<template>
  <div>
    <div>
      <div class="row" style="justify-content: left; margin: 0; padding: 0">
        <v-col class="col-space" cols="4" sm="4" md="4" style="margin-right: 0" v-if="user &&(user.role_id == '612a40a51a1fcead8871e833' || user.role_id == '611532b19c6e77ad0fd50c1c' )">
          <v-select
            v-model="appId"
            :items="items"
            item-value="id"
            item-text="name"
            label="App Name #  "
            class="app-name"
            style="min-width: 100%;"
            @input="selectAppName(appId)"
            outlined
            >
          </v-select>
        </v-col>
        <v-col cols="4" sm="4" md="4" class="text-right pl-3 col-space" style="margin-right: 0px"
        >
          <v-select
            v-model="currencyType"
            :items="roleCurrency"
            item-value="id"
            item-text="name"
            label="Currency Type #  "
            class="currency-type"
            style="min-width: 100%"
            @input="selectCurrencyType(currencyType)"
            outlined
          >
          </v-select>
        </v-col>
        <v-col class="col-space" cols="4" sm="4" md="4" style="margin-right: 0">
          <treeselect
            :multiple="true"
            :limit="3"
            placeholder="Filter Games"
            :options="getAllAppMenu"
            v-model="menuValue"
            :clearable="false"
            @input="selectMenu(menuValue)"
          />
        </v-col>
      </div>
      <div class="row" style="justify-content: left; margin: 0; padding: 0">
        <div class="col-md-4 col-sm-4 bottom-space">
          <v-menu
            v-model="v_startDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
            class="pl-5"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="startDate"
                :label="$t('header.start')"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
              >
              </v-text-field>
            </template>
            <v-date-picker v-model="startDate" @input="v_startDate = false">
            </v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-4 col-sm-4 bottom-space">
          <v-menu
            v-model="v_endDate"
            :close-on-content-click="false"
            :nudge-right="100"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="endDate"
                :label="$t('header.finish')"
                readonly
                v-bind="attrs"
                v-on="on"
                filled
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              @input="v_endDate = false"
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="col-md-4 col-sm-4 bottom-space" style="margin-top: 15px;">
          <div class="input-group">
          <input
            type="text"
            class="form-control"
            v-model="v_search"
            placeholder="Search"
            style="padding: 20px;  border-top-left-radius: 5px; border-bottom-left-radius: 5px;"
          />
          <div class="input-group-btn">
            <button
              class="btn btn-primary"
              @click="getData()"
              style="
                border-radius: 0px;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
                line-height: 2.5;
                background-color: #1976d2 !important;
              "
            >
              <i class="fas fa-search" style="border-radius: 0 !important"></i>
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import moment from "moment";
// import the component
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: { Treeselect },
  props: [
    "darkmode",
    "status",
    "params",
    "dataDelete",
    "userPermission",
    "user",
    "time",
    "startTime",
    "endTime",
    "getAllAppMenu",
  ],
  data() {
    return {
      data: {
        descendingFormat: [true],
        sortByFormat: ["amount_win"],
        descending: true,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          "_id.app_id",
          "_id.com_id",
          "_id.com",
          "_id.con_balance",
          "_id.admin_id",
          "_id.admin",
          "_id.admin_balance",
          "_id.master_id",
          "_id.master",
          "_id.master_balance",
          "_id.agent_id",
          "_id.agent",
          "_id.agent_balance",
          "amount",
          "amount_win",
        ],
        search: "",
        startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
        endDate: moment().format("YYYY-MM-DD"),
        statusTime: false,
        channelType: [0]
      },
      appId : 0,
      items: [
        { name: "All", id: 0 },
        { name: "SBC2888", id: 1 }
      ],
      currencyType: 1,
      currency_types: [
        { name: "Dollar", id: 1 },
        { name: "Riel", id: 2 },
        { name: "Dong", id: 4 },
        { name: "Baht", id: 5 },
      ],
      menuValue: [0],
      startDate: moment().format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD"),
      v_startDate: null,
      v_endDate: null,
      v_startTime: "00:00",
      v_endTime: "23:59",
      v_search: "",
      statusTime: false,
      selectedMenu: []
    };
  },
  watch: {
    time: function (newVal) {
      // Emit this information to the parents component
      this.startDate = newVal.startDate;
      this.endDate = newVal.endDate;
      this.v_startTime = newVal.startTime;
      this.v_endTime = newVal.endTime;
      this.statusTime = newVal.statusTimeOrigin;
    },
  },
  mounted() {
    this.$cookies.set("app-id", this.appId);
    this.$cookies.set("currency-type", this.currencyType);
     
  },
  async created(){
    await this.defaultCurrency();
  },
  computed: {
    titleInfo: function () {
      return "Agent Statement";
    },
    reportUrl: function () {
      return "agent";
    },
    roleCurrency: function(){
      if (this.user) {
        if(this.user.role_name == "SENIOR-D" || this.user.role_name == "SUB-SENIOR-D" || 
           this.user.role_name == "MASTER-D" || this.user.role_name == "SUB-MANAGER-D" || 
           this.user.role_name == "AGENT-D" || this.user.role_name == "SUB-AGENT-D"){
            return [{ name: "Dollar", id: 1 }];
        }else if(this.user.role_name == "SENIOR-R" || this.user.role_name == "SUB-SENIOR-R" || 
           this.user.role_name == "MASTER-R" || this.user.role_name == "SUB-MANAGER-R" || 
           this.user.role_name == "AGENT-R" || this.user.role_name == "SUB-AGENT-R"){
            return [{ name: "Riel", id: 2 }];
        }else if(this.user.role_name == "SENIOR-VN" || this.user.role_name == "SUB-SENIOR-VN" || 
           this.user.role_name == "MASTER-VN" || this.user.role_name == "SUB-MANAGER-VN" || 
           this.user.role_name == "AGENT-VN" || this.user.role_name == "SUB-AGENT-VN"){
            return [{ name: "Dong", id: 4 }];
        }else if(this.user.role_name == "SENIOR-RP" || this.user.role_name == "SUB-SENIOR-RP" || 
           this.user.role_name == "MASTER-RP" || this.user.role_name == "SUB-MANAGER-RP" || 
           this.user.role_name == "AGENT-RP" || this.user.role_name == "SUB-AGENT-RP"){
            return [{ name: "Baht", id: 5 }];
        }else{
          return this.currency_types;
        }
      }
      return this.currency_types;
    },
    ...mapGetters("$_modules", {
      user: "getUser",
      userInfo: "getUserInfo",
    }),
  },
  methods: {
    async defaultCurrency(){
      await this.fetchUser();
      if (this.user) {
        if(this.user.role_name == "SENIOR-D" || this.user.role_name == "SUB-SENIOR-D" || 
           this.user.role_name == "MASTER-D" || this.user.role_name == "SUB-MANAGER-D" || 
           this.user.role_name == "AGENT-D" || this.user.role_name == "SUB-AGENT-D"){
            this.currencyType = 1;
        }else if(this.user.role_name == "SENIOR-R" || this.user.role_name == "SUB-SENIOR-R" || 
           this.user.role_name == "MASTER-R" || this.user.role_name == "SUB-MANAGER-R" || 
           this.user.role_name == "AGENT-R" || this.user.role_name == "SUB-AGENT-R"){
            this.currencyType = 2;
        }else if(this.user.role_name == "SENIOR-VN" || this.user.role_name == "SUB-SENIOR-VN" || 
           this.user.role_name == "MASTER-VN" || this.user.role_name == "SUB-MANAGER-VN" || 
           this.user.role_name == "AGENT-VN" || this.user.role_name == "SUB-AGENT-VN"){
            this.currencyType = 4;
        }else if(this.user.role_name == "SENIOR-RP" || this.user.role_name == "SUB-SENIOR-RP" || 
           this.user.role_name == "MASTER-RP" || this.user.role_name == "SUB-MANAGER-RP" || 
           this.user.role_name == "AGENT-RP" || this.user.role_name == "SUB-AGENT-RP"){
            this.currencyType = 5;
        }else{
          return this.currency_types;
        }
      }
    },
    selectMenu(menuValue) {
      this.menuValue = menuValue;
    },
    selectCurrencyType(currencyType) {
      this.$cookies.set("currency-type", currencyType);
      this.currencyType = parseInt(this.$cookies.get("currency-type"));
    },
    selectAppName(appId) {
      this.$cookies.set("app-id", appId);
      this.appId = parseInt(this.$cookies.get("app-id"));
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async getData() {
      this.startTime = this.v_startTime;
      this.endTime = this.v_endTime;
      let formatStartDate = "";
      let formatEndDate = "";
      formatStartDate = this.startDate + "T" + this.startTime + ":00";
      formatEndDate = this.endDate + "T" + this.endTime + ":59";
      this.data.startDate = formatStartDate;
      this.data.endDate = formatEndDate;
      this.data.search = this.v_search;
      this.data.statusTime = this.statusTime ? true : false;
      this.data.channelType = this.getSelectedMenu();
      let preData = {
        data: this.data,
        appId:this.appId,
        reportUrl: this.reportUrl,
      };
      this.fetchListStatement(preData).then(() => {
        let date = {
          appId:this.appId,
          startDate: formatStartDate,
          endDate: formatEndDate,
          statusTime: this.statusTime,
          search: this.v_search,
          channelType: this.data.channelType
        };
        this.$emit("child-checkbox", date);
      });
    },
    getSelectedMenu(){
      let all = ["1", "2", "3", "4","5", "6", "7", "8", "9", "10", "11", "12", "13", "14","15", "16", "17", "18", "19", "20","21", 
                 "90", "901", "902","91", "911", "912", "92", "921", "922", "79","791",
                 "93", "931", "932", "933", "934",
                 "94","941", "942", "943", "944", "945", "946", "947", "948", "949",
                 "95","951", "952", "953", "954", "955", "956", "957", "958", "959",
                 "96", "961", "962", "963", "964","97","971", "972", "973", "974",
                 "98","981", "982", "983", "984", "99", "991", "992","89", "891", "892"];
      let cock = ["1", "2", "3", "4","15", "16"];
      let casino = ["5", "6", "7", "8", "9", "10", "11", "12", "13", "14","17", "18", "19", "20","21"];
      let lotto_game = ["90", "901", "902","91", "911", "912", "92", "921", "922", "99", "991", "992","89", "891", "892","79","791"];
      let lotto = ["90", "901", "902"], yuki = ["91", "911", "912"], lottery= ["92", "921", "922"], keno = ["99", "991", "992"];
      let machine_game = ["93", "931", "932", "933", "934","94","941", "942", "943", "944", "945", "946", "947", "948", "949",
                           "95","951", "952", "953", "954", "955", "956", "957", "958", "959",
                           "96", "961", "962", "963", "964","97","971", "972", "973", "974", "98","981", "982", "983", "984"];
      let dragon = ["94","941", "942", "943", "944", "945", "946", "947", "948", "949"];
      let baccarat = ["95","951", "952", "953", "954", "955", "956", "957", "958", "959"];
      let taixiu = ["98","981", "982", "983", "984"], doden = ["96", "961", "962", "963", "964"];
      let sicbo = ["97","971", "972", "973", "974"], klaklouk = ["93", "931", "932", "933", "934"];
      let lottery3 = ["89", "891", "892"],lotteryvn = ["79","791"];
      if(this.menuValue.length > 0){
        this.selectedMenu = [];
        for(let i in this.menuValue){
          if(this.menuValue[i] == 0){
            this.selectedMenu = all;
          }else if(this.menuValue[i] == 'cock'){
            this.selectedMenu = [...this.selectedMenu, ...cock];
          }else if(this.menuValue[i] == 'casino'){
            this.selectedMenu = [...this.selectedMenu, ...casino];
          }else if(this.menuValue[i] == 'lotto_game'){
            this.selectedMenu = [...this.selectedMenu, ...lotto_game];
          }else if(this.menuValue[i] == 'lotto'){
            this.selectedMenu = [...this.selectedMenu, ...lotto];
          }else if(this.menuValue[i] == 'yuki'){
            this.selectedMenu = [...this.selectedMenu, ...yuki];
          }else if(this.menuValue[i] == 'lottery'){
            this.selectedMenu = [...this.selectedMenu, ...lottery];
          }else if(this.menuValue[i] == 'keno'){
            this.selectedMenu = [...this.selectedMenu, ...keno];
          }else if(this.menuValue[i] == 'machine_game'){
            this.selectedMenu = [...this.selectedMenu, ...machine_game];
          }else if(this.menuValue[i] == 'dragon'){
            this.selectedMenu = [...this.selectedMenu, ...dragon];
          }else if(this.menuValue[i] == 'baccarat'){
            this.selectedMenu = [...this.selectedMenu, ...baccarat];
          }else if(this.menuValue[i] == 'taixiu'){
            this.selectedMenu = [...this.selectedMenu, ...taixiu];
          }else if(this.menuValue[i] == 'doden'){
            this.selectedMenu = [...this.selectedMenu, ...doden];
          }else if(this.menuValue[i] == 'sicbo'){
            this.selectedMenu = [...this.selectedMenu, ...sicbo];
          }else if(this.menuValue[i] == 'klaklouk'){
            this.selectedMenu = [...this.selectedMenu, ...klaklouk];
          }else if(this.menuValue[i] == 'lottery3'){
            this.selectedMenu = [...this.selectedMenu, ...lottery3];
          }else if(this.menuValue[i] == 'lotteryvn'){
            this.selectedMenu = [...this.selectedMenu, ...lotteryvn];
          }else {
            this.selectedMenu.push(this.menuValue[i].toString());
          }
        }
      }else{
        this.selectedMenu = all;
      }
      return this.selectedMenu;
    },
    ...mapActions("$_ocStatementSummaryAgentUSD", ["fetchListStatement"]),
    ...mapActions("$_modules", [ "fetchUser"]),
  },
};
</script>

<style lang="scss" scoped>
.btn-custom {
  position: relative;
  // right: 250px;
  top: 10px;
}
.tab-filter {
  display: flex;
  flex-direction: row;
}
.row {
  display: flex;
  flex-direction: row;
}
.between {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}
.w-full {
  width: 100%;
}
.custom-menu {
  max-width: 11%;
}

.custom-input-time {
  border: 1.5px solid black;
  padding: 2px 10px;
  border-radius: 5px;
  margin-top: 10px;
}
.col-space {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .bottom-space {
    margin-top: 0;
    padding-top: 0;
  }
</style>
